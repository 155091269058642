@import "tailwindcss";

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

body {
  font-family: "Poppins", sans-serif;
}

video::-webkit-media-controls-enclosure {
  display: none !important;
}

::-webkit-slider-thumb {
  -webkit-appearance: "none";
  appearance: "none";
  width: "16px";
  height: "16px";
  background-color: "#E0E2BE";
  border-radius: "100%";
  cursor: "pointer";
}

::-webkit-slider-runnable-track {
  background: "none";
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  background: #5883d5;
  border-radius: 5px;
  border-color: #a3a7ad;
}

@media (min-width: 769px) {
  .hidden-big-screen {
    display: none;
  }
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideLeftHidden {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0; /* Mantiene la visibilidad antes de que termine */
    visibility: visible; /* Asegura que sea visible hasta casi el final */
  }
  100% {
    opacity: 0;
    visibility: hidden; /* Se oculta al final */
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.slide-down-animation {
  animation: slideDown 0.5s ease-out;
}

.slide-up-animation {
  animation: slideUp 0.5s ease-out;
}


@media (max-width: 768px) { /* Puedes ajustar 768px según tus necesidades */
  .slide-left-animation {
    animation: slideLeft 0.5s ease-out;
  }
}

@media (max-width: 768px) { /* Puedes ajustar 768px según tus necesidades */
  .slide-left-hidden-animation {
    animation: slideLeftHidden 0.5s ease-out forwards; /* 'forwards' mantiene el estado final */
  }
}

@keyframes hide {
  0% {
    width: 52px;
  }
  99% {
    width: 0;
  }
  100% {
    visibility: hidden; /* Se oculta al final */
  }
}

@keyframes hideHeigth {
  0% {
    height: 196px;
  }
  100% {
    height: 0;
    visibility: hidden; /* Se oculta al final */
  }
}

@keyframes expandHeigth {
  0% {
    visibility: hidden; /* Se oculta al final */
    height: 0;
  }
  100% {
    height: 196px;
  }
}

@keyframes expand {

  
  from {
    width: 0;
    
  }
  to {
    width: 100%;
  }
}

.appear-animation {
  animation: appear 0.5s ease-out;
}

.hide-controls {
  /* Oculta todos los controles del video */
  /* Puedes personalizar los estilos según tus necesidades */
  .video-controls {
    display: none;
  }
}

.boton {
  color: white;
  background-color: #2e5da1; /* Color original */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease; /* Transición suave */
}

/* Estilo cuando el ratón pasa sobre el botón */
.boton:hover {
  color: #fff; /* Color de texto al pasar el ratón */
}

.text-word-wrap {
  width: 100px;
  height: auto;
  word-wrap: break-word;
}

.text-word-sm {
  width: 180px;
  height: auto;
  word-wrap: break-word;
}

.text-word-md {
  width: 200px;
  height: auto;
  word-wrap: break-word;
}

.text-word-lg {
  width: 300px !important;
  height: auto;
  word-wrap: break-word;
}
